import React from 'react'
import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { HTMLContent } from './Content'
import { remark } from 'remark'
import remarkHtml from 'remark-html'
import NewsPreview from './NewsPreview'
import accent from '../img/accent2.svg'
import arrow from '../img/arrow.svg'
import Navbar from './Navbar'
import Footer from './Footer'
import Helmet from 'react-helmet'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import CustomForm from './CustomForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCreditCard, faTruck, faMedkit } from '@fortawesome/free-solid-svg-icons'

import ImageBar from './ImageBar'

const HomePageTemplate = ({
  welcomeMessage,
  welcomeAside,
}) => (
  < >
    <Helmet title="Ricewood Municipal Utility District" />
    <Helmet>
      <meta name="google-site-verification" content="Qoxj4Hlzxo9ST0EsOtRgfo8wnSStUJIgr_Bo3HEibeI" />
    </Helmet>
    <div className="">
      <Navbar />
      <section className="section__home-page">
        <ImageBar />
        <div className="container">
          <div className="columns accent-offset" style={{ marginTop: '2rem' }}>
            <div className="column is-3" style={{ paddingTop: '1rem' }}>
              {/* <p><Link className="button is-warning"  to="/election-information">2023 Bond Election</Link></p> */}
              {/* <p><Link className="button is-primary"  to="/es/election-information">Información Electoral</Link></p>
            <p><Link className="button is-primary"  to="/vi/election-information">Thông tin bầu cử</Link></p>
            <p><Link className="button is-primary"  to="/zh/election-information">選舉信息</Link></p> */}
              {/* <hr /> */}
              <p><Link className="button" to="/election-information#board-of-directors-elections">Board of Directors Elections</Link></p>
              <p><Link className="button" to="/es/election-information#board-of-directors-elections">Elecciones de la Junta Directiva</Link></p>
              <p><Link className="button" to="/vi/election-information#board-of-directors-elections">Các Cuộc Bầu Cử Ban Giám Đốc</Link></p>
              <p><Link className="button" to="/zh/election-information#board-of-directors-elections">董事會選舉</Link></p>
              <hr></hr>
              <p><strong>The 2023 Drinking Water Quality Report / Consumer Confidence Report is now available below.</strong></p>
              <p><a href="https://www.inframark.com/wp-content/uploads/ricewood.pdf">https://www.inframark.com/wp-content/uploads/ricewood.pdf</a></p>
              <hr></hr>
              <h2 class="title is-size-3" style={{ marginTop: '3rem' }}>Quick Links</h2>
              <p><Link className="button is-warning" to="/bill-payment"><span className="icon"><FontAwesomeIcon icon={faCreditCard} /></span> Pay your water bill</Link></p>
              <p><Link className="button is-warning" to="/tax-information"><span className="icon"><FontAwesomeIcon icon={faHome} /></span> Pay your MUD tax</Link></p>
              <p><Link className="button is-warning" to="/garbage-collection"><span className="icon"><FontAwesomeIcon icon={faTruck} /></span> Garbage Collection Info</Link></p>
              <p><Link className="button is-warning" to="/first-responders"><span className="icon"><FontAwesomeIcon icon={faMedkit} /></span> First Responders</Link></p>
              <h3 class="title is-size-4" style={{ marginTop: '3rem' }}>Numbers to know</h3>
              <p><small><strong class="has-text-danger" style={{ textTransform: 'uppercase' }}>Residents should always call 9-1-1 during a life-threatening emergency.</strong></small></p>
              <p>
                <strong>Harris County Sheriff’s Office (District 4)</strong><br />
                Non Emergency: (713) 221-6000<br />
                Information Line: (713) 755-6044
              </p>
              <p>
                <strong>Harris County Precinct 4 Comissioner - Lesley Briones</strong><br />
                Email: <a href="mailto:commissioner.briones@hcp4.net">commissioner.briones@hcp4.net</a>
              </p>
              <p>
                <strong>Westlake Volunteer Fire Department</strong><br />
                Main: (281) 492-0560
              </p>
              <p>
                <strong>Harris County Emergency Services District 48</strong><br />
                Phone: (281) 599-8888<br />
                Email: info@hcesd48.org
              </p>
              <p>
                <strong>GFL Environmental (formerly WCA)</strong><br />
                Phone: (713) 670-9199
              </p>
              <p>
                <strong>Katy Christian Ministries</strong><br />
                Phone: (281) 391-9623
              </p>
            </div>
            <div className="column is-8">
              <NewsPreview />
              <div className="columns is-nested">
                <div className="column is-5" style={{ padding: '1.5rem' }}>
                  <div className="card" style={{ marginTop: '2rem', padding: '1rem' }}>
                    <h3 className="is-size-4" style={{ marginBottom: '1rem' }}>Sign up to receive e-news!</h3>
                    <MailchimpSubscribe
                      url="https://ricewoodmud.us19.list-manage.com/subscribe/post?u=9e9660a8cc948aa82bf3acc40&amp;id=aeb70a5fae"
                      render={({ subscribe, status, message }) => (
                        <CustomForm
                          status={status}
                          message={message}
                          onValidated={formData => subscribe(formData)}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="column is-3" style={{ padding: '1.5rem' }}>
                  <Link to="/latest-updates" className="blog-button" style={{ marginTop: '2rem' }}>
                    <img src={arrow} alt="" /><br />
                    SEE ALL<br />
                    LATEST UPDATES
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-6 is-offset-1 ">
            <div className="content">
              <HTMLContent content={remark().use(remarkHtml).processSync(welcomeMessage).toString()} />
            </div>
          </div>
          <div className="column is-4 vertical-justify">
            <Image className="asideImage" fixed={welcomeAside.childImageSharp.fixed} alt="" />
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </ >
)

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  welcomeMessage: PropTypes.string,
}

export default HomePageTemplate
